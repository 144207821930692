import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import './App.css';

localStorage.debug = '*';
const socket = io('https://backend.sorucevap.me', {
    transports: ['websocket', 'polling'],
    secure: true
});

function App() {
  const [username, setUsername] = useState('');
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [joined, setJoined] = useState(false);
  const [users, setUsers] = useState([]);
  const [userScores, setUserScores] = useState({});
  const messagesEndRef = useRef(null);

  useEffect(() => {
    socket.on('chat message', (msg) => {
      console.log('Alınan mesaj:', msg);
      setMessages((prevMessages) => [...prevMessages, msg]);
    });

    socket.on('user joined', ({ username, users, userScores }) => {
      setMessages((prevMessages) => [...prevMessages, { username: 'Sistem', text: `${username} sohbete katıldı` }]);
      setUsers(users);
      setUserScores(userScores);
    });

    socket.on('user left', ({ username, users, userScores }) => {
      setMessages((prevMessages) => [...prevMessages, { username: 'Sistem', text: `${username} sohbette ayrıldı` }]);
      setUsers(users);
      setUserScores(userScores);
    });

    socket.on('score update', (userScores) => {
      setUserScores(userScores);
    });

    return () => {
      socket.off('chat message');
      socket.off('user joined');
      socket.off('user left');
      socket.off('score update');
    };
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const joinChat = () => {
    if (username.trim()) {
      socket.emit('join', username);
      setJoined(true);
    }
  };

  const sendMessage = () => {
    if (message.trim()) {
      socket.emit('chat message', message);
      console.log('Gönderilen mesaj:', message);
      setMessage('');
    }
  };

  return (
    <div className="App">
      {!joined ? (
        <div className="join-chat">
          <input
            type="text"
            placeholder="Kullanıcı adınızı girin"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <button onClick={joinChat}>Sohbete Katıl</button>
        </div>
      ) : (
        <div className="chat-container">
          <div className="sidebar">
            <h2>Kanal: Genel</h2>
            <h3>Kullanıcılar</h3>
            <ul>
              {users.map((user, index) => (
                <li key={index}>{user} ({userScores[user] || 0} puan)</li>
              ))}
            </ul>
          </div>
          <div className="main-chat">
            <h1>Sohbet Uygulaması</h1>
            <div className="messages">
              {messages.map((msg, index) => (
                <div key={index} className={`message ${msg.isBot ? 'bot-message' : ''}`}>
                  <strong>{msg.username}:</strong> {msg.text}
                </div>
              ))}
              <div ref={messagesEndRef} />
            </div>
            <div className="input-container">
              <input
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
              />
              <button onClick={sendMessage}>Gönder</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
